@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.discoverPage-Header-Section {
  margin-top: 42px;
}

@media (min-width: 768px) {
    .discoverPage-Header-Section {
      text-align: center;
    }
  }

.discoverPage-header {
    width: 80%; /* Use percentage to allow scaling */
    max-width: 100%; /* Prevent header from exceeding container width */
    height: auto; /* Let content determine height */
    margin: 42px auto 0; /* Center and add top margin */
    font-family: 'Inria Sans', sans-serif;
    font-weight: 700;
    font-size: 1.375rem; /* Relative units for responsive scaling */
    line-height: 1.5; /* Improved line height */
  }

@media (min-width: 768px) {
    .discoverPage-header {
      font-size: 28px; 
      line-height: 34px; 
      max-width: 100%; 
      margin: 42px auto; 
    }
  }

.news-feed-list {
    margin-bottom: 100px;
}

@media (min-width: 768px) {
    .news-feed-list {
        margin-bottom: 5%;
    }
  }

.news-feed-item {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    flex-wrap: wrap;
    width: 100%;
}

.news-image {
    width: 100px; 
    height: 100px; 
    border-radius: 8px;
    object-fit: cover;
    max-width: 100%;
}

.news-image-container {
    display: flex;
    align-items: center; 
    margin-top: 20px;
    margin-left: 20px;
}

.news-content {
    display: flex;
    flex-direction: column;
    width: 205px;
    height: 32px;
    margin-top: 20px;
    margin-left: 20px;
}

.news-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 20.05px;
    margin-top: 5px;
    font-family: 'Abhaya Libre', serif;
    color: #252525;
}

.news-subtitle {
    font-size: 12px; 
    color: #252525;
    font-family: 'Abhaya Libre', serif;
    margin-top: 0px; 
}

@media (min-width: 768px) {
    .news-feed-item {
        flex-direction: row; 
    } 
    .news-image {
        width: 150px; 
        height: 150px;
        border-radius: 8px;
        object-fit: cover;
    }
    .news-image-container {
        margin-left: 1300px;
    }
    .news-content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .news-title {
        margin-top: 60px;
        font-size: 35px; 
    }
    .news-subtitle {
        font-size: 25px; 
    }
}



  