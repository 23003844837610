/* .iss-page-header {
  font-size: calc(20px + 2vmin);
  align-items: center;
  text-align: left;
  padding-left: 2%;
  display: flex;
} */

.back-icon {
  height: 40px;
}

.back-button {
  border: none;
  padding: 5px 5px;
  cursor: pointer;
  background-color: #D9D9D9;
}

.iss-header {
  background-color: #D9D9D9;
}

.iss-style {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.iss-container {
  background-color: #D9D9D9;
  min-height: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.back-button {
  position: absolute;
  top: 3%;
  left: 3%;
  text-decoration: none;
  font-size: 16px;
}

header {
  text-align: center;
  margin-bottom: 10px;
}

header h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
  text-align: center;
}

main {
  line-height: 1;
  color: black;
  margin-left: 5%;
}

.iss-function {
  font-size: 28px;
}

.iss-service {
  font-size: 24px;
  color: #615F5F;
  line-height: 0.8;
}

.iss-subtitle {
  font-size: 20px;
  color: #615F5F;
}

.iss-name {
  font-size: 22px;
  color: black;
  line-height: 1.6;
}

.iss-info {
  display: inline;
}