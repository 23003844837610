.home-back-icon {
  height: 20px;
}

.home-back-button {
  border: none;
  padding: 1% 1%;
  cursor: pointer;
  background-color: white;
}

.home-header {
  background-color: white;
}

.home-style {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.home-container {
  background-color: white;
  min-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.2);
}

.home-back-button {
  position: absolute;
  top: 2%;
  left: 2%;
  text-decoration: none;
  font-size: 16px;
}

.home-card-header {
  text-align: center;
  margin-bottom: 2%;
  margin-top: 2%;
  font-size: 34px;
  text-align: center;
  color: #4b2e83;
  font-weight: bold;
}


main {
  line-height: 1;
  color: black;
  margin-left: 5%;
  margin-right: 5%;
}

.home-function {
  font-size: 28px;
  text-align: center;
}

.home-subtitle {
  font-size: 20px;
  color: black;
}

