.Homepage {
  text-align: left
}

.search {
  height: 20px;
}

.Homepage-header {
  background-color: #F5F5F5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.homepage-header {
  color: #4b2e83;
  font-size: calc(20px + 2vmin);
  align-items: center;
  text-align: left;
  padding-left: 5%;
  margin-top: 2%;
}

.search-container {
  display: flex;
  justify-content: left;
  margin-top: 10px;
  padding-left: 5%;
}

.search-bar {
  border-radius: 30px;
  padding: 10px 20px;
  width: 80%;
  display: flex;
  align-items: center;
  background-color: #D9D9D9;
}

.search-input {
  border: none;
  outline: none;
  width: 100%;
  font-size: large;
  padding: 5px;
  background-color: #D9D9D9;
}


.search-button {
  border: none;
  padding: 5px 5px;
  cursor: pointer;
  background-color: #D9D9D9;
}

.onboard {
  padding-left: 4%; /* Added left padding */
  padding-right: 4%;
  font-size: calc(5px + 1vmin);
}

.onboard-header {
  font-size: 24px;
  color: black;
  text-align: left;
}


.cards-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 100px;
}

.card-wrapper {
  flex: 0 1 calc(25% - 10px); 
  margin: 5px;
  box-sizing: border-box;
}

.card {
  position: relative;
  width: 80%;
  border: 2px solid #ccc;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-img {
  width: 96%;
  max-height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-top: 2%;
  margin-left: 2%;
  position: relative;
}

.card-content {
  padding-top: 2%;
  padding-left: 2%;
}

.card-title {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;
  text-align: left;
}

.card-subtitle {
  font-size: 16px;
  color: #666;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: left;
}


.card-favorite {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 24px;
  color: white;
  cursor: pointer;
  -webkit-text-stroke: 1px gold;
}

.card-favorite.active {
  color: gold;
  -webkit-text-stroke: none;

}

.bottom-nav {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #ccc;
}

.nav-item {
    flex-grow: 1;
    text-align: center;
    border-radius: 4px;
    margin: 10px;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: black; /* Default text color, change as needed */
}

.nav-item i {
    font-size: 24px; /* Icon size, adjust as needed */
    margin-bottom: 5px; /* Space between icon and text */
}

.nav-item:hover {
    background-color: #f0f0f0; /* Hover effect, optional */
}

/* Example media query for responsiveness */
@media (max-width: 600px) {
    .nav-item i {
        font-size: 20px;
    }
    .nav-item span {
        font-size: 14px;
    }
}

.attention-section {
  padding-left: 4%; /* Added left padding */
  padding-right: 4%;
  font-size: calc(5px + 1vmin);
}

.attention-header {
  font-size: 24px;
  color: black;
  text-align: left;
}

.content-box {
  max-height: 300px;
  overflow-y: auto;
  border: 1.5px solid white;
  padding: 20px;
  margin-top: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.info-item h3 {
  font-weight: 700;
  font-size: 22px;
  line-height: 26.38px;
  color: #3c2a51;
}

.info-item ul {
  margin-top: 5px;
}

.info-item li {
  color: #3c2a51;
}

.nav-item.active {
  background-color: #4b2e83; /* Change to red when active */
  color: white;
}



/*making the boxes titles scalable using media queries using the width given from flex in card.wrapper*/
/*At max-width: 1200px, the cards will switch to 3 per rowx*/
@media (max-width: 1200px) {
  .card-wrapper {
    flex: 0 1 calc(33.33% - 10px);
  }
  
  .card-title {
    font-size: 20px;
  }

  .card-subtitle {
    font-size: 14px;
  }

  .card-favorite {
    font-size: 20px;
  }
}





/*At max-width: 900px, the cards will switch to 2 per row.x*/
@media (max-width: 900px) {
  .card-wrapper {
    flex: 0 1 calc(50% - 10px); /* 2 cards per row */
  }
  
  .card-title {
    font-size: 18px;
  }

  .card-subtitle {
    font-size: 12px;
  }

  .card-favorite {
    font-size: 18px;
  }
}


/*At max-width: 600px, the cards will switch to 1 per row*/
@media (max-width: 600px) {
  .card-wrapper {
    flex: 0 1 100%; /* 1 card per row */
  }
  
  .card-title {
    font-size: 16px;
  }

  .card-subtitle {
    font-size: 12px;
  }

  .card-favorite {
    font-size: 16px;
  }
}
